import { default as aboutwDaWN0gPCNMeta } from "/home/webpages/hochwacht-frontend/pages/about.vue?macro=true";
import { default as _91id_93x60bGA2kemMeta } from "/home/webpages/hochwacht-frontend/pages/agegroups/[id].vue?macro=true";
import { default as contactjzGzDS0guuMeta } from "/home/webpages/hochwacht-frontend/pages/contact.vue?macro=true";
import { default as documentsw9Wj6wAbUQMeta } from "/home/webpages/hochwacht-frontend/pages/documents.vue?macro=true";
import { default as donationkcWlL4seW0Meta } from "/home/webpages/hochwacht-frontend/pages/donation.vue?macro=true";
import { default as equipment7D6aCjnDG6Meta } from "/home/webpages/hochwacht-frontend/pages/equipment.vue?macro=true";
import { default as faqHA0kVmjb5IMeta } from "/home/webpages/hochwacht-frontend/pages/faq.vue?macro=true";
import { default as _91id_93Q4sMBZD7wlMeta } from "/home/webpages/hochwacht-frontend/pages/galleries/[id].vue?macro=true";
import { default as indexveA6RAgMU0Meta } from "/home/webpages/hochwacht-frontend/pages/galleries/index.vue?macro=true";
import { default as impressumwRf1uuCJJQMeta } from "/home/webpages/hochwacht-frontend/pages/impressum.vue?macro=true";
import { default as indexyrT9vPHYygMeta } from "/home/webpages/hochwacht-frontend/pages/index.vue?macro=true";
import { default as privacypolicyQcI97mphdZMeta } from "/home/webpages/hochwacht-frontend/pages/privacypolicy.vue?macro=true";
import { default as rentalKzT846CNYyMeta } from "/home/webpages/hochwacht-frontend/pages/rental.vue?macro=true";
import { default as rentalimagesWPoL8RGOu4Meta } from "/home/webpages/hochwacht-frontend/pages/rentalimages.vue?macro=true";
import { default as _91_46_46_46slug_93bjrTntsRLvMeta } from "/home/webpages/hochwacht-frontend/pages/resources/[...slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/webpages/hochwacht-frontend/pages/about.vue")
  },
  {
    name: "agegroups-id",
    path: "/agegroups/:id()",
    component: () => import("/home/webpages/hochwacht-frontend/pages/agegroups/[id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/webpages/hochwacht-frontend/pages/contact.vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/home/webpages/hochwacht-frontend/pages/documents.vue")
  },
  {
    name: "donation",
    path: "/donation",
    component: () => import("/home/webpages/hochwacht-frontend/pages/donation.vue")
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/home/webpages/hochwacht-frontend/pages/equipment.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/webpages/hochwacht-frontend/pages/faq.vue")
  },
  {
    name: "galleries-id",
    path: "/galleries/:id()",
    component: () => import("/home/webpages/hochwacht-frontend/pages/galleries/[id].vue")
  },
  {
    name: "galleries",
    path: "/galleries",
    component: () => import("/home/webpages/hochwacht-frontend/pages/galleries/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/home/webpages/hochwacht-frontend/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/webpages/hochwacht-frontend/pages/index.vue")
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    component: () => import("/home/webpages/hochwacht-frontend/pages/privacypolicy.vue")
  },
  {
    name: "rental",
    path: "/rental",
    component: () => import("/home/webpages/hochwacht-frontend/pages/rental.vue")
  },
  {
    name: "rentalimages",
    path: "/rentalimages",
    component: () => import("/home/webpages/hochwacht-frontend/pages/rentalimages.vue")
  },
  {
    name: "resources-slug",
    path: "/resources/:slug(.*)*",
    component: () => import("/home/webpages/hochwacht-frontend/pages/resources/[...slug].vue")
  }
]